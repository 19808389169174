import * as React from 'react';

function IconInterfaceEssentialClockTime(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 24" fill="none" {...props}>
      <path
        d="M16.641 16l-4.922-2.936V6.736"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.364 5.636A9 9 0 115.636 18.364 9 9 0 0118.364 5.636"
        stroke="currentColor"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const MemoIconInterfaceEssentialClockTime = React.memo(
  IconInterfaceEssentialClockTime
);
export default MemoIconInterfaceEssentialClockTime;
