import * as React from 'react';

function IconArrowRight(props) {
  return (
    <svg width="1em" height="1em" viewBox="0 0 24 25" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.037 7.074l-1.411 1.418 3.27 3.255-13.605.013.002 2 13.568-.013-3.215 3.23 1.417 1.41 5.644-5.67-5.67-5.643z"
        fill="currentColor"
      />
    </svg>
  );
}

const MemoIconArrowRight = React.memo(IconArrowRight);
export default MemoIconArrowRight;
