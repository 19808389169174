/* eslint-disable react/prop-types */
import * as React from 'react';

import Button from '@dolstaff/shared/es/Button';
import format from 'date-fns/format';
import vi from 'date-fns/locale/vi';
import { Link, graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import DolInfo from 'shared/DolInfo';
import Tag from 'shared/SchedulePage/Tag';
import { colorsV2 } from 'style/colors-v2';
import { formatDayOfWeek, formatClassTime } from 'utils/dateFormat';

import { deviceBreakpoints } from '../../utils/hooks/useDeviceQuery.hook';
import IconArrowRight from './icons/IconArrowRight';
import IconFlagLightDuotone from './icons/IconFlagLightDuotone';
import IconInterfaceEssentialCalendarSchedule from './icons/IconInterfaceEssentialCalendarSchedule';
import IconInterfaceEssentialCalendarSchedule2 from './icons/IconInterfaceEssentialCalendarSchedule2';
import IconInterfaceEssentialClockTime from './icons/IconInterfaceEssentialClockTime';
import { ScheduleTable } from '../CourseLanding/Detail/ClassScheduleOfLevel';
import { withHomePageSection } from 'components/withHomePageSection';
import { Typography } from 'components/DesignSystemV2/Typography';
import { fromScreen } from 'utils/media-query/responsive.util';
import { getName } from 'utils';

const Wrapper = styled.div`
  .schedule-title {
    margin-bottom: 24px;
    ${fromScreen(1144)} {
      text-align: center;
      margin-bottom: 40px;
    }
  }
`;

const Main = styled.div`
  .card-schedule {
    padding: 24px;
    border: 2px solid #e9e9ec;
    border-radius: 16px;
    width: 352px;
    min-width: 352px;

    .card-schedule-icon {
      margin-right: 19px;
      font-size: 24px;
      line-height: 0;
      color: #7c7f88;
    }
    .card-schedule-title {
      font-size: 20px;
      font-weight: 600;
      line-height: 28px;
      color: ${colorsV2.black100};
    }
    .card-schedule-header {
      margin-bottom: 24px;
    }
    .card-schedule-body {
      font-weight: normal;

      .row {
        margin-bottom: 12px;
        &:last-child {
          margin-bottom: 0;
        }
      }
      .align-center {
        align-items: center;
      }
      .text {
        font-size: 16px;
        line-height: 24px;
        color: ${colorsV2.black100};
      }
      .sub-text {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        color: ${colorsV2.black80};
      }
      .highlight {
        font-weight: 500;
        border-bottom: 2px solid #ff991f;
        &.red {
          border-color: #d14242;
        }
      }
    }
    .card-review-footer {
    }
  }
  .btn-inbox {
    margin-top: 20px;
    button {
      background-color: #f4f4f6;
      border: none;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: #242938;
    }
  }
  .cta-link {
    margin-top: 32px;
    button {
      border-radius: 8px;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: ${colorsV2.black100};
      border: 2px solid #d14242;
      .iconRight > svg {
        color: ${colorsV2.green100};
      }
    }
  }

  @media ${deviceBreakpoints.mobile} {
    .wrapper {
      .layout-columns {
        overflow: auto;
        flex-wrap: nowrap;
        width: 100%;
        align-items: stretch;
        justify-content: flex-start;
        .column {
          display: block;
          flex: 0 1 auto;
          .card-schedule {
            width: 342px;
            min-width: 342px;
          }
        }
      }
    }
  }
`;

const ArrowRight = styled(IconArrowRight)`
  color: ${colorsV2.primary100};
  font-size: 24px;
`;

const GCMSStudentCourseReviews = ({ data }) => {
  return (
    <Main>
      <div className="wrapper">
        <div className="layout-columns">
          {data.map(colItem => {
            const {
              id,
              startDate,
              classTime,
              classDate,
              classStatus,
              levelDisplay
            } = colItem.node;

            const startDateFormatted = format(
              new Date(startDate),
              'dd MMMM, yyyy',
              {
                locale: vi
              }
            );

            const dayOfWeek = formatDayOfWeek(classDate);
            const isFull = classStatus === 'Hết chỗ';
            const isNearFull = classStatus === 'Gần hết chỗ';

            return (
              <div key={id} className="column flex">
                <div className="card-schedule">
                  <div className="row align-center card-schedule-header">
                    <div className="card-schedule-icon">
                      <IconFlagLightDuotone />
                    </div>
                    <div className="card-schedule-title flex">
                      {levelDisplay.replace(/ielts online/gi, '')}
                    </div>
                  </div>
                  <div className="card-schedule-body">
                    <div className="row align-center">
                      <div className="card-schedule-icon">
                        <IconInterfaceEssentialCalendarSchedule />
                      </div>
                      <div className="flex">
                        <div className="sub-text">Khai giảng ngày</div>
                        <div className="row">
                          <div className="text">
                            <span className="highlight">
                              {startDateFormatted}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-center">
                      <div className="card-schedule-icon">
                        <IconInterfaceEssentialClockTime />
                      </div>
                      <div className="flex">
                        <div className="sub-text">Thời gian học</div>
                        <div className="row">
                          <div className="text">
                            <span className="highlight">{dayOfWeek}</span> vào
                            lúc <span className="highlight">{classTime}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row align-center">
                      <div className="card-schedule-icon">
                        <IconInterfaceEssentialCalendarSchedule2 />
                      </div>
                      <div className="flex">
                        <div className="sub-text">Chỗ trống & địa chỉ</div>
                        <div className="row">
                          {isFull && (
                            <span>
                              <Tag
                                textColor={'#ffffff'}
                                type="filled"
                                color="#DE350B"
                              >
                                Hết chỗ
                              </Tag>
                              &nbsp;
                            </span>
                          )}
                          {isNearFull && (
                            <span className="highlight red">Còn chỗ&nbsp;</span>
                          )}
                          <div className="text">
                            tại <span className="highlight">DOL Quận 10</span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="btn-inbox">
                      <DolInfo>
                        {({ dolInfo }) => (
                          <>
                            <div className="btn-cta">
                              <a
                                href={dolInfo.messenger.href}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Button
                                  full
                                  type="outline"
                                  noMargin
                                  size="large"
                                >
                                  Inbox DOL
                                </Button>
                              </a>
                            </div>
                          </>
                        )}
                      </DolInfo>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="cta-link">
        <Link href="/schedule">
          <Button
            style={{ borderColor: colorsV2.green100 }}
            size="large"
            type="outline"
            IconRight={ArrowRight}
          >
            Xem lịch học và học bổng
          </Button>
        </Link>
      </div>
    </Main>
  );
};

const studentOnlineCourseScheduleQuery = graphql`
  query StudentOnlineCourseScheduleQuery {
    allGoogleSpreadsheetDolLandingPageMarLevelData(
      filter: {
        classListing: { eq: "listed" }
        classGroup: { eq: "IELTS Online" }
      }
      sort: { fields: [levelDisplay], order: ASC }
    ) {
      nodes {
        levelDisplay
        id
        startDate
        classTime
        classDate
        classStatus
        scheduleBranchName
      }
    }
  }
`;

export const toClassSchedule = (data, level) => {
  return data.allGoogleSpreadsheetDolLandingPageMarLevelData.nodes
    .filter(item => item.level === level && Boolean(item.classDate))
    .map(({ startDate, classDate, classStatus, classTime, levelDisplay }) => {
      return {
        dateOpening: format(new Date(startDate), 'dd/MM/yyyy'),
        dateOpeningMobile: format(new Date(startDate), 'dd/MM/yyyy'),
        dayOfWeek: formatDayOfWeek(classDate),
        classStatus: classStatus,
        duration: formatClassTime(classTime),
        scheduleBranchName: getName(levelDisplay)
      };
    });
};

const StudentReviews = () => {
  const data = toClassSchedule(
    useStaticQuery(studentOnlineCourseScheduleQuery)
  );

  return (
    <Wrapper>
      <Typography
        variant="bold/24-32"
        tabletVariant="bold/32-40"
        desktopVariant="bold/40-48"
        v3
        className="schedule-title"
        as="h2"
      >
        Lịch học lớp IELTS Online
      </Typography>
      <ScheduleTable data={data} onlineCourse />
    </Wrapper>
  );
};

export default withHomePageSection(StudentReviews);
